import React, { ReactElement, useState, useRef, useCallback } from 'react'
import Slider from 'react-slick'
import { graphql } from 'gatsby'
import Seo from '../../components/Seo/Seo'
import './Amenities.scss'
import next from '../../assets/images/next.png'
import back from '../../assets/images/back.png'
import FullScreenIcon from '../../assets/images/icons/fullScreen.svg.png'
import useDraggable from '../../hooks/useDraggable'
import MinusIcon from '../../assets/images/icons/minus-icon.png'
import PlusIcon from '../../assets/images/icons/plus-icon.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const PropertyNavigationSliced = loadable(() =>
  import('../../components/PropertyNavigationSliced/PropertyNavigationSliced'),
)

type TowerTypes = {
  name: string
  locationLandmarks?: {
    seoLocationDescription: string
    seoLocationTitle: string
    locationBrief: string
    locationDefault: string
    locationSpecific: string
    latitude: string
    longitude: string
    region: string
    locationImages?: {
      url: string
      featured: string
    }[]
    landmarksEstablishment?: {
      items: {
        establishmentName: string
        establishmentBody: string
        establishmentDistance: string
        establishmentLocation: string
        establishmentLatitude: string
        establishmentLongitude: string
        imagesGallery: {
          description: string
          url: string
          featured: boolean
        }[]
      }[]
    }
  }
  constructionUpdates?: {
    seoConstructionPlanTitle: string
    seoConstructionPlanDescription: string
    constructionEndDate: string
    details: string
    constructions?: {
      constructionUpdate: string
      details: string
    }[]
    imageGallery: {
      url: string
    }[]
  }
  amenities?: {
    title?: string
    propertyDetails?: string
    seoAmenitiesTitle: string
    seoAmenitiesDescription: string
    items: {
      amenityName: string
      images: {
        url: string
      }[]
    }[]
  }
  overviewDetails?: {
    seoOverviewDescription: string
    seoOverviewTitle: string
    propertyStatus: string
    propertyDetails: string
    propertyTitle: string
    imagesGallery: [
      {
        url: string
        featured: boolean
      },
    ]
    panoramas?: {
      url: string
      featured: string
    }[]
  }
}

export type PropertyDataTypes = {
  id: string
  propertyType: string
  originalId: string
  name: string
  overviewDefault: string
  slug: string
  towers: TowerTypes[]
  location: string
}

type AmenityPropTypes = {
  data: {
    amenityData: PropertyDataTypes
  }
}

const Amenities = ({
  data: { amenityData },
}: AmenityPropTypes): ReactElement => {
  const [currentAmenity] = useState<TowerTypes | null>(
    amenityData.towers ? amenityData.towers[0] || null : null,
  )
  const sliderRef = useRef<Slider>(null)
  const mobileSliderRef = useRef<Slider>(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [fullScreen, setFullScreen] = useState(false)
  const [selected, setSelected] = useState('')

  const [displayFeaturedImages, setDisplayFeaturedImages] = useState<string[]>(
    amenityData.towers[0]?.amenities?.items[0]?.images?.map(
      (image) => image.url,
    ) || [],
  )

  const [activeKey, setActiveKey] = useState(0)

  const [zoomLvl, setZoomLvl] = useState(1)

  const DraggableCard = ({ children }: any) => {
    const cardRef = useRef(null)
    if (zoomLvl > 1) {
      useDraggable(cardRef)
    }

    return (
      <div className="card" ref={cardRef}>
        {children}
      </div>
    )
  }

  const handleBeforeChange = useCallback(
    (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex)
    },
    [],
  )

  const handleAmenity = useCallback(
    (index) => {
      setActiveKey(index)
      setDisplayFeaturedImages(
        amenityData.towers[0]?.amenities?.items[index]?.images?.map(
          (image) => image.url,
        ) || [],
      )
    },
    [amenityData],
  )

  const nextImageMobile = useCallback(() => {
    if (mobileSliderRef?.current?.slickNext) {
      mobileSliderRef.current.slickNext()
    }
  }, [mobileSliderRef])

  const prevImageMobile = useCallback(() => {
    if (mobileSliderRef?.current?.slickPrev) {
      mobileSliderRef.current.slickPrev()
    }
  }, [mobileSliderRef])

  const nextImage = useCallback(() => {
    if (sliderRef?.current?.slickNext) {
      sliderRef.current.slickNext()
    }
  }, [sliderRef])

  const prevImage = useCallback(() => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef.current.slickPrev()
    }
  }, [sliderRef])

  const goToSlide = useCallback(
    (index: number) => {
      if (sliderRef?.current?.slickGoTo) {
        sliderRef.current.slickGoTo(index)
      }
    },
    [sliderRef],
  )

  const [mainTower] = useState<TowerTypes | null>(
    amenityData.towers ? amenityData.towers[0] || null : null,
  )
  const aerialView = mainTower?.overviewDetails?.panoramas
    ? mainTower?.overviewDetails?.panoramas[0]?.url
    : null

  const seoTitle = amenityData.towers[0]?.amenities?.seoAmenitiesTitle
  const defaultTitle = `${amenityData.name} | Amenities`

  const seoDescription =
    amenityData.towers[0]?.amenities?.seoAmenitiesDescription

  return (
    <Layout>
      <Seo
        title={seoTitle ? seoTitle : defaultTitle}
        jsonData={{
          '@type': 'Profile',
          description: seoDescription
            ? seoDescription
            : amenityData.towers[0]?.amenities?.propertyDetails,
        }}
      />
      <div className="amenities">
        <PropertyNavigationSliced
          propertyType={amenityData.propertyType}
          propertySlug={amenityData.slug}
          propertyLocation={amenityData.location}
        />
        <div className="amenities-list">
          <h4 className="title">{amenityData.towers[0]?.amenities?.title}</h4>
          <h4 className="tagline">
            {amenityData.towers[0]?.amenities?.propertyDetails}
          </h4>

          {currentAmenity?.amenities?.items.map((amenity, key) => {
            return (
              <button
                type="button"
                key={amenity.amenityName}
                onClick={(): void => handleAmenity(key)}
                className={activeKey === key ? 'items selected' : 'items'}
              >
                {amenity.amenityName}
              </button>
            )
          })}
        </div>
        {fullScreen && (
          <div className="amenities-fullscreen">
            <LazyLoadImage
              src={selected}
              alt="amenities image"
              placeholder={<img id="placeholder" />}
            />
            <img
              src={FullScreenIcon}
              onClick={() => {
                setFullScreen(false)
              }}
              alt="[]"
              id="fullscreen"
            />
          </div>
        )}
        <div className="amenities-image-slider">
          <Slider
            swipeToSlide={false}
            arrows={false}
            dots={false}
            draggable={false}
            beforeChange={handleBeforeChange}
            fade
            easing="ease"
            className="banner-slider"
            ref={sliderRef}
          >
            {displayFeaturedImages.map((image, i) => (
              <div className="slider-image" key={i}>
                <img
                  src={FullScreenIcon}
                  onClick={() => {
                    setFullScreen(true)
                    setSelected(image)
                  }}
                  alt="[]"
                  id="fullscreen"
                />
                <DraggableCard>
                  <LazyLoadImage
                    key={image}
                    src={image}
                    placeholder={<img id="placeholder" />}
                    style={{ transform: `scale(${zoomLvl})` }}
                    alt="slider image"
                  />
                  <div style={{ transform: `scale(${zoomLvl})` }}></div>
                </DraggableCard>
                <div
                  className="zoom-btns"
                  style={{ padding: '2px', background: '#Fff' }}
                >
                  <img
                    src={PlusIcon}
                    style={{ borderBottom: '1px solid #c5c5c6' }}
                    onClick={() =>
                      setZoomLvl((prev) => {
                        if (prev * 1.25 > 3) {
                          return prev
                        }
                        return prev * 1.25
                      })
                    }
                    alt="+"
                  />
                  <img
                    src={MinusIcon}
                    alt="-"
                    onClick={() =>
                      setZoomLvl((prev) => {
                        if (prev / 1.25 < 1) {
                          return 1
                        }
                        return prev / 1.25
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </Slider>
          <div className="nav-image-container">
            <h4>Gallery</h4>
            <div className="container">
              {displayFeaturedImages.map((image, index) => (
                <button
                  type="button"
                  key={image}
                  onClick={(): void => goToSlide(index)}
                >
                  <LazyLoadImage
                    src={image}
                    placeholder={<img id="placeholder" />}
                    className={`slider-nav${
                      currentSlide === index ? ' active' : ''
                    }`}
                    alt="property image"
                  />
                </button>
              ))}
            </div>
          </div>
          <div className="nav-button-container">
            <button
              type="button"
              className="nav"
              onClick={(): void => prevImage()}
            >
              <img src={back} alt="backIcon" />
            </button>
            <button
              type="button"
              className="nav"
              onClick={(): void => nextImage()}
            >
              <img src={next} alt="nextIcon" />
            </button>
          </div>
        </div>
        <div className="slider-nav-container" />
      </div>
      <div className="amenities-mobile">
        <div className="amenities-image-slider">
          <Slider
            swipeToSlide={false}
            arrows={false}
            dots={false}
            beforeChange={handleBeforeChange}
            fade
            easing="ease"
            className="banner-slider"
            ref={mobileSliderRef}
          >
            {displayFeaturedImages.map((image) => (
              <LazyLoadImage
                key={image}
                className="slider-image"
                alt="slider-image"
                src={image}
                placeholder={<img id="placeholder" />}
              />
            ))}
          </Slider>
          <div className="nav-button-container">
            <button
              type="button"
              className="nav"
              onClick={(): void => prevImageMobile()}
            >
              <img src={back} alt="backIcon" />
            </button>
            <button
              type="button"
              className="nav"
              onClick={(): void => nextImageMobile()}
            >
              <img src={next} alt="nextIcon" />
            </button>
          </div>
        </div>
        <PropertyNavigationSliced
          propertyType={amenityData.propertyType}
          propertySlug={amenityData.slug}
          propertyLocation={amenityData.location}
        />
        <div className="amenities-list">
          <h4 className="title">{amenityData.towers[0]?.amenities?.title}</h4>
          <h4 className="tagline">
            {amenityData.towers[0]?.amenities?.propertyDetails}
          </h4>

          {currentAmenity?.amenities?.items.map((amenity, key) => {
            return (
              <button
                type="button"
                key={amenity.amenityName}
                onClick={(): void => handleAmenity(key)}
                // className="items"
                className={activeKey === key ? 'items selected' : 'items'}
              >
                {amenity.amenityName}
              </button>
            )
          })}
        </div>
        <div className="slider-nav-container" />
      </div>
    </Layout>
  )
}

export default Amenities

export const pageQuery = graphql`
  query GhostPostAndGhostPostQuery($id: String!) {
    amenityData: property(id: { eq: $id }) {
      ...PropertyPageFields
    }
  }
`
